import React from 'react';
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';
import { signOut } from '../../../store/actions/authActions';
import { setMenu } from '../../../store/actions/menuActions';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Redirect, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AccountCircleOutlined , ListAltOutlined, Security, DescriptionOutlined, AccountBalance, DateRange, BarChart, ImportExport, Add, Group, ViewListOutlined, DnsOutlined } from '@material-ui/icons';
import MailIcon from '@material-ui/icons/Mail';
import { withStyles, makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import { Tooltip } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  active: {
    boxShadow: "rgba(0, 0, 0, 0.87) 4px 0px 0px inset",
    backgroundColor: '#a3b1ff',
  },
  inactive: {

  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const Navbar = (params) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { authStatus, profile, signOut, menu } = params;
  const { isEmpty, isLoaded } = authStatus;
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Kérdőívek' })
    history.push(`/`);
    // dispatch({ type: actions.MEASURE_FORM, payload: { lab: 1, unit: 2 } })
  };
  const handleSubmitLabs = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Intézmények' })
    history.push(`/institutions`);
  };

  const handleSubmitForm = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Mintavételek' })
    history.push(`/sampling`);
  };

  const handleSubmitAdmin = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Adminisztrátorok' })
    history.push(`/admins`);
  };

  const handleSubmitImport = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Tesztfelvételek' })
    history.push(`/filled`);
  };

  const handleSubmitUsers = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Felhasználók' })
    history.push(`/users`);
  };

  const handleSubmitStat = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Statisztikák' })
  };

  const handleSubmitPermissions = () => {
    dispatch({ type: actions.SET_MENU, payload: 'Résztvevők' })
    history.push(`/permissions`);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const theme = createMuiTheme({
    palette: {
      primary: green,
      backgroundColor: purple[700],
    },
  });

  const signout2 = () => {
    signOut();
  }
  console.log(',,,,,,,,,,,,,,,,,,,,', params)

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        {/* <Toolbar>
          <Typography variant='h6' color='inherit' className={classes.title}>
            Kérdőív Admin
          </Typography>
          {isLoaded &&
            (isEmpty ? (
              <SignedOutLinks></SignedOutLinks>
            ) : (
              <SignedInLinks
                signOut={signOut}
                profile={profile}
              ></SignedInLinks>
            ))}
        </Toolbar> */}
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' color='inherit' className={classes.title}>
            {params.menu}
          </Typography>
          {isLoaded &&
            (isEmpty ? (
              <SignedOutLinks></SignedOutLinks>
            ) : (
              <SignedInLinks
                signOut={signout2}
                profile={profile}
              ></SignedInLinks>
            ))}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>

          <Tooltip placement="left" title='Kérdőívek' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmit()} disabled={menu == 'Kérdőívek'} className={menu == 'Kérdőívek' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <DescriptionOutlined />
              </ListItemIcon>
              <ListItemText primary={"Kérdőívek"} />
            </ListItem>
          </Tooltip>

          <Tooltip placement="left" title='Intézmények' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitLabs()} disabled={menu == 'Intézmények'} className={menu == 'Intézmények' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <AccountBalance />
              </ListItemIcon>
              <ListItemText primary={"Intézmények"} />
            </ListItem>
          </Tooltip>
          
          <Tooltip placement="left" title='Adminisztrátorok' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitAdmin()} disabled={menu == 'Adminisztrátorok'} className={menu == 'Adminisztrátorok' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <Security  />
              </ListItemIcon>
              <ListItemText primary={"Adminisztrátorok"} />
            </ListItem>
          </Tooltip>

          <Tooltip placement="left" title='Mintavételek' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitForm()} disabled={menu == 'Mintavételek'} className={menu == 'Mintavételek' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <DateRange />
              </ListItemIcon>
              <ListItemText primary={"Mintavételek"} />
            </ListItem>
          </Tooltip>

          <Tooltip placement="left" title='Tesztfelvételek' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitImport()} disabled={menu == 'Tesztfelvételek'} className={menu == 'Tesztfelvételek' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <ListAltOutlined />
              </ListItemIcon>
              <ListItemText primary={"Tesztfelvételek"} />
            </ListItem>
          </Tooltip>

          <Tooltip placement="left" title='Felhasználók' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitUsers()} disabled={menu == 'Felhasználók'} className={menu == 'Felhasználók' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <AccountCircleOutlined />
              </ListItemIcon>
              <ListItemText primary={"Felhasználók"} />
            </ListItem>
          </Tooltip>

          <Tooltip placement="left" title='Statisztika' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitStat()} disabled={menu == 'Statisztika'} className={menu == 'Statisztika' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <BarChart />
              </ListItemIcon>
              <ListItemText primary={"Statisztika"} />
            </ListItem>
          </Tooltip>

          <Tooltip placement="left" title='Résztvevők' disableHoverListener={open}>
            <ListItem onClick={() => handleSubmitPermissions()} disabled={menu == 'Résztvevők'} className={menu == 'Résztvevők' ? classes.active : classes.inactive} button key={"text"}>
              <ListItemIcon>
                <Group />
              </ListItemIcon>
              <ListItemText primary={"Résztvevők"} />
            </ListItem>
          </Tooltip>
        </List>
        <Divider />
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authStatus: state.firebase.auth,
    profile: state.firebase.profile,
    menu: state.questionnaire.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
