import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/UI/navigation/Navbar';
import AddQuestionnaire from './components/UI/projects/AddQuestionnaire';
import UpdateQuestionnaire from './components/UI/projects/UpdateQuestionnaire';
import Dashboard from './components/UI/dashboard/Dashboard';
import SignIn from './components/UI/auth/SignIn';
import SignUp from './components/UI/auth/SignUp';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { green, blueGrey } from '@material-ui/core/colors';
import Evaluation from './components/UI/projects/Evaluation';
import Statistics from './components/UI/projects/Statistics';
import FilledView from './components/UI/projects/FilledView';
import PermissionsEditor from './components/UI/dashboard/PermissionsEditor';
import Institutions from './components/UI/institutions/Institutions';
import SamplingComponent from './components/UI/sampling/Sampling';
import Admins from './components/UI/admins/Admins';
import Filledquestionnaires from './components/UI/Filledquestionnaires';
import UsersComponent from './components/UI/UsersComponent';

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: blueGrey[500],
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={outerTheme}>
      <Router
        basename={'#'}
      >
        <Switch>
          <Route exact path='/signin' component={SignIn} />
          <Route exact path='/signup' component={SignUp} />
          <Route path={["/", "/users", "/admins", "/sampling", "/edit/:id", "/evaluation/:id", "/filled/:id", "/statistics/:id", "/permissions"]}>
            <div style={{ flexGrow: 1, alignItems: 'center' }}>
              <Navbar />
              <Switch>
                <Route exact path='/permissions' component={PermissionsEditor} />
                <Route exact path='/institutions' component={Institutions} />
                <Route exact path='/' component={Dashboard} />
                <Route exact path='/sampling' component={SamplingComponent} />
                <Route exact path='/admins' component={Admins} />
                <Route exact path='/users' component={UsersComponent} />
                <Route exact path='/edit/:id' component={UpdateQuestionnaire} />
                <Route exact path='/evaluation/:id' component={Evaluation} />
                <Route exact path='/filled' component={Filledquestionnaires} />
                <Route exact path='/filledview/:id' component={FilledView} />
                <Route exact path='/statistics/:id' component={Statistics} />
              </Switch>
            </div>
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
