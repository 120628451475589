import { withStyles, makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const drawerWidth = 240;
export default makeStyles(theme => ({
    root: {
        flexGrow: 1,
        alignItems: 'center',
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
    table: {
        minWidth: '550px',
        padding: '0 !important'
    },
    breadcrumbs: {
        textAlign: 'left',
        margin: theme.spacing(1)
    },
    inactive: {
        color: '#2eb85c'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginTop: theme.spacing(6),
        // [theme.breakpoints.down('sm')]: {
        //     backgroundColor: theme.palette.secondary.main,
        //     maxWidth: 'sm'
        //   },
        //   [theme.breakpoints.up('md')]: {
        //     backgroundColor: theme.palette.primary.main,
        //     maxWidth: 'sm'
        //   },
        //   [theme.breakpoints.up('lg')]: {
        //     backgroundColor: '#2eb85c',
        //     maxWidth:'sm'
        //   },
    },
    parameter: {
        fontSize: '1.5rem',
        fontWeight: '600'
    },
    textarea: {
        resize: "both",
        minWidth: '520px !important',
        minHeigh: '550px',
        maxHeigh: '600px',
        height: '200',
    },
    normal: {
    },
    alert: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        padding: '0 !important'
    },
    dialog: {
        minWidth: '550px',
        minHeigh: '250px',
    },
    csvdialog: {
        minWidth: '600px',
        minHeigh: '350px !important',
    },
    datetime: {
        marginTop: '0px !important',
    },
    error: {
        backgroundColor: "#ffd7db !important",
    },
    processed: {
        backgroundColor: "#a2cf6e !important",
    },
    tablefilters: {
        textAlign: 'right',
        margin: theme.spacing(1),
        display: 'flex'
    },
    tableCell: {
        padding: "0px 8px"
    },
    customTable: {
        "& .MuiTableCell-sizeSmall": {
          padding: "1px 1px 1px 1px" // <-- arbitrary value
        }
      },

}));
